// Partials importieren
@import 'partials/_base';
@import 'partials/_colors';
@import 'partials/_variables';

// Globaler Zeichenabstand
$global-letter-spacing: -.2px;

body {
  letter-spacing: $global-letter-spacing;
}

.react-joyride__tooltip button{
  outline: none !important;
}

@media screen and (max-width: 375px) {
  html {
    hyphens: auto;
  }
}